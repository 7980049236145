import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {TgButton} from "../models/tgbutton";

@Injectable({
  providedIn: 'root'
})
export class TelegramApiService {

  private window;
  tg;
  constructor(@Inject(DOCUMENT) private _document) {
    this.window = this._document.defaultView;
    this.tg = this.window.Telegram.WebApp;
  }

  get MainButton(): TgButton {
    return this.tg.MainButton;
  }

  get BackButton(): TgButton {
    return this.tg.BackButton;
  }

  sendData(data: object) {
    this.tg.sendData(JSON.stringify(data));
  }

  showPopup(paprams){
    this.tg.showPopup(paprams);
  }
  ready() {
    this.tg.ready();
  }

  closeApp() {
    this.tg.close();
  }



}

