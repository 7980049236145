import {inject, Injectable} from '@angular/core';
import {HttpHandlerFn, HttpRequest} from "@angular/common/http";
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token:string = environment.zakupayToken;

  constructor() {}

  getAuthToken():string {
    return this.token;
  }
}

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const authToken = inject(AuthService).getAuthToken();
  const newReq = req.clone({
    headers:req.headers.append('ZakupayToken', authToken),
    });
  return next(newReq);
}
export function apiInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const baseUrl:string = environment.portalApi;

  const newReq  = req.clone({
    url: baseUrl + req.url
  });
  return next(newReq);
}

