import {FormGroup} from "@angular/forms";
import {Account} from "./account";
import {CompanyDocument} from "./company";
import {OrderMain, OrderItem} from "./order-main";
import {RecognitionRes} from "./utils";

export class ServerDataForOffer {
  order:OrderMain;
  accounts:Account[]
  documents: CompanyDocument[];
  offerWithBankGuarantee:boolean;
  showCompanyDocumentOnPortal:boolean;
  offerCompanyDocumentRequired:boolean;
  canSetOfferCompanyDocument:boolean;
}


export class OuterOfferCreateModel {
  orderId: number;
  offerViewModel: Partial<RecognitionRes>;
  accountId: Account;
  currencyId:number;
  brandId: any;
  documentRegNum:any;
  delay: boolean;
  validityPeriod: number;
  prepaidPercent: number;
  requestId:any;
  code:any;
  orderItemIds: number[];
  orderItems: OrderItem[];
  documentId: number;
  withoutVAT: any;
  companyDocumentId: number;
  deliveryIncluded: boolean;
  bankGuarantee: boolean;
  recognizedBik: any;
  recognizedCurrentAccount: any;
  comment: string;
  fillInClient: boolean;
  transactCheckbox: boolean;
  filesForChat: any[];

  constructor() {
  }

  public static createFromForms(formgroup:FormGroup,
                                fileId: number,
                                items: OrderItem[],
                                orderId: number,
                                recognizeResult:RecognitionRes): OuterOfferCreateModel {
    const outerModel: OuterOfferCreateModel = new OuterOfferCreateModel();
    const ctrls = formgroup.controls;
    outerModel.bankGuarantee = ctrls['bankGuarantee']?.value || null;
    outerModel.accountId = ctrls['accounts'].value.id;
    outerModel.companyDocumentId = ctrls['companyDocument']?.value?.id || null;
    outerModel.delay = ctrls['delay'].value;
    outerModel.deliveryIncluded = ctrls['deliveryIncluded'].value;
    outerModel.documentId = fileId;
    outerModel.fillInClient = false;
    outerModel.orderItems = items;
    outerModel.filesForChat = [];
    outerModel.orderId = orderId;
    outerModel.orderItemIds = items.map(oi => oi.id);
    outerModel.orderItems = items;
    outerModel.prepaidPercent = ctrls['prepaidPercent'].value;
    outerModel.validityPeriod = ctrls['validityPeriod'].value || 0;
    outerModel.transactCheckbox = false;
    outerModel.comment = ctrls['comment'].value;
    outerModel.offerViewModel = null || recognizeResult;
    outerModel.recognizedBik = null || recognizeResult?.recognizedBik;
    outerModel.recognizedCurrentAccount = null || recognizeResult?.recognizedCurrentAccount;
    outerModel.brandId = null;
    outerModel.code = null;
    outerModel.requestId = null;
    outerModel.withoutVAT = null;
    outerModel.currencyId = null;
    return outerModel;
  }
}
