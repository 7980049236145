import {Company} from "./company";

class OrderMain {
  public id: number;
  public orderItems: OrderItem[];
  public customer:Company;
}
class OrderItem {
  id: number;
  goodName: string;
  count: number;
  unit: any;
  checked: boolean;
  availableCount: number;
  isAvailable: boolean;
  deliveryDays: number;
  price: number;
  amount: number;
  skipCount: number;
}
class RecognizedOrderItem {
  sourceUnitname:string;
  amount:number;
  count:number;
  goodName:string;
  offerCount:number;
  offerGoodName:string;
  price:number;
  sourceCount:number;
  unitName:string;
}
export {RecognizedOrderItem, OrderItem, OrderMain}
