import {CUSTOM_ELEMENTS_SCHEMA, forwardRef, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import {AccountNameTransform, AppComponent} from './app.component';
import {NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {PortalApiService} from "./services/portal-api.service";
import {MatTreeModule} from "@angular/material/tree";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {DialogService} from "./services/dialog.service";
import {MatFormField} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatProgressBar} from "@angular/material/progress-bar";
import {TranslateLoader, TranslateModule, TranslateStore} from "@ngx-translate/core";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatFormFieldModule} from '@angular/material/form-field';
import {HttpClient, provideHttpClient, withInterceptors} from '@angular/common/http';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {apiInterceptor, authInterceptor} from "./services/auth.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatInputModule} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {TelegramApiService} from "./services/telegram-api.service";
//core/assets/spa2/assets/locale/ru.json
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // return new TranslateHttpLoader(http,'../assets/locale/', '.json')
  return new TranslateHttpLoader(http,  'core/assets/spa2/assets/locale/');
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatFormField,
    MatIcon,
    MatProgressBar,
    TranslateModule,
    MatSelect,
    MatOption,
    AccountNameTransform,
    MatCheckbox,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ru',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatProgressSpinner,
    MatIconButton,
  ],
  providers: [TelegramApiService, PortalApiService, provideAnimationsAsync(),
              DialogService,  provideHttpClient(withInterceptors([authInterceptor,apiInterceptor])), TranslateStore],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

})
export class AppModule { }
