import {inject, Injectable} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {OuterOfferCreateModel, ServerDataForOffer} from "../models/server-data-for-offer";
import {HttpClient, HttpHandlerFn, HttpRequest} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {FileUploadResult, RecognitionRes, ResultWithError} from "../models/utils";

@Injectable({
  providedIn: 'root'
})
export class PortalApiService {

  constructor(private http: HttpClient) {

  }

  public getCompanyDocuments(companyInn: string, customerId:number): Observable<Partial<ServerDataForOffer>> {
    return this.http.get<Partial<ServerDataForOffer>>(`core/company-documents/portal?companyInn=${companyInn}&customerId=${customerId}`);
  }

  public sendToProcess(data: OuterOfferCreateModel): Observable<any> {
    return this.http.post(`core/offers/newouter`, data);
  }

  public sendRecognizeRequest(fileId: number): Observable<RecognitionRes> {
    return this.http.get<RecognitionRes>(`core/files/recognize/${fileId}?counter=1`);
  }

  public sendCheckDuplicatesRequest(id: number): Observable<ResultWithError> {
    return this.http.get<ResultWithError>(`core/offers/${id}/checkOfferFileDuplicates`);
  }

  public uploadFile(formData: FormData): Observable<any> {
    return this.http.post(`core/supplier/uploadFile`, formData);
  }

  public uploadFileReal(formData: FormData, orderId:number): Observable<FileUploadResult> {
    return this.http.post<FileUploadResult>(`core/fill-documents/upload/registryfile?orderId=${orderId}`, formData);
  }

  public getOrderData(telegramCode:string): Observable<ServerDataForOffer> {
    return this.http.get<ServerDataForOffer>(`core/supplier/getorderfromtelegram/${telegramCode}/`);
  }

}

