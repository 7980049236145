<div *ngIf="loadingOrderInfo" class="spinner-block">
  <mat-spinner [diameter]="200"></mat-spinner>
</div>
<div *ngIf="!loadingOrderInfo" class="main-content">
  <div>
    <H3>{{ 'telegramApp.sendOfferTitle' | translate }}</H3>
  </div>
  <form class="offer-form" [formGroup]="mainForm">
    <mat-progress-bar mode="indeterminate" *ngIf="fileLoading"></mat-progress-bar>
    <mat-form-field class="attach-file_field">
      <mat-label>{{ 'telegramApp.attachFile' | translate }}</mat-label>
      <button mat-icon-button matPrefix (click)="f_input.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
      <input type="text" readonly matInput formControlName="fileControl"/>
      <input type="file" class="file-input" #f_input (change)="addOfferFile(f_input.files[0])"/>
      <button matSuffix mat-icon-button aria-label="Clear" (click)="deleteFile()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <label class="label-for-dropdown">{{ 'telegramApp.setAccount' | translate }}</label>
    <mat-form-field class="form-group select-dropdown accounts-mat-field" appearance="fill">
      <mat-label>{{ 'telegramApp.setAccount' | translate }}</mat-label>
      <mat-select class="form-control " id="accounts" placeholder="{{ 'telegramApp.accountPlaceHolder' | translate }}"
                  [compareWith]="compareObjectsById"
                  formControlName="accounts">
        <input matInput class="searchModel" placeholder="{{ 'telegramApp.searchPlaceholder' | translate }}"
               (keyup)="startAccountSearch()"
               #accountSearch autofocus>
        <mat-option *ngFor="let p of accounts" [value]="p">{{ p | accountNameTransform }}</mat-option>
      </mat-select>
    </mat-form-field>

    <label *ngIf="showCompanyDocumentOnPortal && mainForm.get('accounts').valid && companyDocumentList.length > 0" class="label-for-dropdown">{{ 'telegramApp.setCompanyDocument' | translate }}</label>
    <div *ngIf="showCompanyDocumentOnPortal && mainForm.get('accounts').valid && companyDocumentList.length > 0" class="grouped-dropdown">
      <mat-form-field class="form-group select-dropdown " appearance="fill">
        <mat-label>{{ 'telegramApp.setCompanyDocument' | translate }}</mat-label>
        <mat-select class="form-control " id="companyDocument" data-live-search="true"
                    placeholder="{{ 'telegramApp.companyDocument' | translate }}" formControlName="companyDocument"
                    (click)="treeControl.expandAll()">
          <input matInput class="searchModel" placeholder="{{ 'telegramApp.searchPlaceholder' | translate }}"
                 (keyup)="startDocumentSearch()" (keydown)="$event.stopPropagation()" #documentSearch autofocus>
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <mat-option [value]="node.item" [disabled]="node.item.disabled">
                {{ node.item.shortTitle }}
              </mat-option>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
              <mat-option [value]="node.item" [disabled]="node.item.disabled">
                <div class="level-doc">
                  <mat-icon class="mat-icon-rtl-mirror"></mat-icon>
                  {{ node.item.shortTitle }}
                </div>
              </mat-option>
            </mat-tree-node>
          </mat-tree>
        </mat-select>
      </mat-form-field>
    </div>

    <label class="label-for-dropdown">{{ 'telegramApp.deliveryIncluded' | translate }}</label>
    <div class="grouped-dropdown">
      <mat-form-field class="form-group select-dropdown" appearance="fill">
        <mat-label>{{ 'telegramApp.delivery' | translate }}</mat-label>
        <mat-select class="form-control " id="deliveryIncluded" formControlName="deliveryIncluded">
          <mat-option [value]="true">{{ 'telegramApp.yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'telegramApp.no' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div></div>
    </div>

    <label class="label-for-dropdown">{{ 'telegramApp.needPrepaid' | translate }}</label>
    <div class="grouped-dropdown">
        <mat-form-field class="form-group select-dropdown" appearance="fill">
          <mat-label>{{ 'telegramApp.prepaid' | translate }}</mat-label>
          <mat-select class="form-control " id="prepaidRequired"
                      formControlName="prepaid" (selectionChange)="updateForm($event)">
            <mat-option [value]="true">{{ 'telegramApp.yes' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'telegramApp.no' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="mainForm.get('prepaid').value" class="form-group select-dropdown" appearance="fill">
          <input class="select-value" matInput type="number" formControlName="prepaidPercent" id="prepaidPercent" (change)="checkPrepaidPercentValue()">
        </mat-form-field>
      </div>

    <label class="label-for-dropdown">{{ 'telegramApp.needDelay' | translate }}</label>
    <div class="grouped-dropdown">
      <mat-form-field class="form-group select-dropdown" appearance="fill">
        <mat-label>{{ 'telegramApp.delay' | translate }}</mat-label>
        <mat-select class="form-control " id="needDelay" (selectionChange)="updateForm($event)"
                    formControlName="needDelay">
          <mat-option [value]="true">{{ 'telegramApp.yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'telegramApp.no' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="mainForm.get('needDelay').value" class="form-group select-dropdown" appearance="fill">
        <input class="select-value" matInput type="number" formControlName="delay" id="delay">
      </mat-form-field>
    </div>

    <label class="label-for-dropdown">{{ 'telegramApp.validityPeriod' | translate }}</label>
    <div class="grouped-dropdown">
      <mat-form-field class="form-group select-dropdown" appearance="fill">
        <mat-label>{{ 'telegramApp.validity' | translate }}</mat-label>
        <input class="select-value" matInput type="number" formControlName="validityPeriod" id="validityPeriod">
      </mat-form-field>
      <div></div>
    </div>

    <label class="label-for-dropdown">{{ 'telegramApp.allAvailable' | translate }}</label>
    <div class="grouped-dropdown">
      <mat-form-field class="form-group select-dropdown" appearance="fill">
        <mat-label>{{ 'telegramApp.availability' | translate }}</mat-label>
        <mat-select class="form-control" id="allAvailable" formControlName="allAvailable" (selectionChange)="updateCounts($event)">
          <mat-option *ngFor="let answer of availableAnswers" [value]="answer">{{ 'telegramApp.' + answer | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <label *ngIf="offerCompanyDocumentRequired" class="label-for-dropdown">{{ 'telegramApp.setRegNum' | translate }}</label>
    <div *ngIf="offerCompanyDocumentRequired" class="grouped-dropdown">
      <mat-form-field class="form-group select-dropdown" appearance="fill">
        <mat-label>{{ 'telegramApp.regNum' | translate }}</mat-label>
        <input class="select-value" matInput type="string" formControlName="docNumber" id="docNumber">
      </mat-form-field>
      <div></div>
    </div>

    <label *ngIf="bankGuarantee" class="label-for-dropdown">{{ 'telegramApp.bankGuarantee' | translate }}</label>
    <div class="grouped-dropdown">
      <mat-form-field *ngIf="bankGuarantee" class="form-group select-dropdown" appearance="fill">
        <mat-label>{{ 'telegramApp.bankGuarantee' | translate }}</mat-label>
        <mat-select class="form-control " id="bankGuarantee" formControlName="bankGuarantee">
          <mat-option [value]="true">{{ 'telegramApp.yes' | translate }}</mat-option>
          <mat-option [value]="false">{{ 'telegramApp.no' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-checkbox class="check-all-checkbox" formControlName="checkAll" (change)="selectAll($event)">
      <label>{{ 'telegramApp.selectAll' | translate }}</label>
    </mat-checkbox>
    <div class="form-group order-items-form">
      <div class="order-items" formArrayName="orderItems" *ngFor="let item of orderItems; let i = index">
        <mat-card class="item" formGroupName={{i}} class="row">
          <mat-card-header>
            <mat-checkbox formControlName="checked"></mat-checkbox>
            <input class="goodName" formControlName="goodName"/>
          </mat-card-header>
          <mat-card-content class="body-card">
            <div class="count">
              <label for="count">{{ 'telegramApp.count' | translate }}</label>
              <div id="count">
                <input class="insert" matInput type="number" style="border: none" formControlName="count">
              </div>
            </div>
            <div class="available">
              <label for="availableCount">В наличии</label>
              <div id="availableCount">
                <input class="insert" matInput type="number" formControlName="availableCount">
              </div>
            </div>
            <div class="delivery-terms">
              <label for="deliveryDays">{{ 'telegramApp.deliveryTerms' | translate }}</label>
              <div id="deliveryDays">
                <input class="insert" matInput type="number" formControlName="deliveryDays">
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="comment-group">
      <label class="label-for-dropdown">{{ 'telegramApp.comment' | translate }}</label>
      <mat-form-field class="form-group select-dropdown" appearance="fill">
        <mat-label>{{ 'telegramApp.comment' | translate }}</mat-label>
        <textarea matInput maxlength="450px" formControlName="comment"></textarea>
      </mat-form-field>
    </div>

    <div class="btn-group">
      <button class="btn-send" [disabled]="fileLoading" (click)="sendToProcess()">{{ 'telegramApp.sendOfferDlgTitle' | translate }}</button>
    </div>
    <div style="height: 5rem">

    </div>
  </form>

</div>
