import {Injectable} from '@angular/core';
import {TelegramApiService} from "./telegram-api.service";

@Injectable()
export class DialogService {
  constructor(private telegramService: TelegramApiService) {

  }

  public handleError(title: string, message: string) {
    this.openNativeTelegramPopup(title, message)
  }

  public createDlg(title: string, message: string) {
    this.openNativeTelegramPopup(title, message)
  }

  public getError(err): string {
    return err.error?.errorMessage || err.message || err;
  }

  private openNativeTelegramPopup(title: string, message: string){
    this.telegramService.showPopup({title:title, message:message});
  }
}
