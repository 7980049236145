import {RecognizedOrderItem} from "./order-main";
import {AbstractControl} from "@angular/forms";

interface IdNameEntity {
  id: number;
  name: string;
}

interface resultAnswer {
  result: boolean
}

interface ResultWithError extends resultAnswer {
  error?: boolean;
  errorMessage?: string
}

interface RecognitionRes extends resultAnswer {
  recognizedCurrentAccount: string,
  producerOfferDate: null,
  producerOfferNumber: string,
  recognizedBik: string,
  items: RecognizedOrderItem[]
}

interface FileUploadResult extends ResultWithError, IdNameEntity {
  infoMessage: string,
  duplicateFileId: number,
  savedFilename: string,
  createTime: number,
  childs: any,
}
function nonZero(control:AbstractControl):{ [key: string]: any; } {
  if (Number(control.value) < 0) {
    return {nonZero: true};
  } else {
    return null;
  }
}

export {RecognitionRes, ResultWithError, IdNameEntity, FileUploadResult, nonZero}
