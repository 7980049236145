import {Component, CUSTOM_ELEMENTS_SCHEMA, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from '@angular/forms'
import {OrderMain,OrderItem} from "./models/order-main";
import {Account} from "./models/account";
import {CompanyDocument} from "./models/company";
import {OuterOfferCreateModel, ServerDataForOffer} from "./models/server-data-for-offer";
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatInput} from "@angular/material/input";
import {MatSelectChange} from "@angular/material/select";
import {PortalApiService} from "./services/portal-api.service";
import {DialogService} from "./services/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconButton} from "@angular/material/button";
import {TelegramApiService} from "./services/telegram-api.service";
import {IdNameEntity, nonZero, RecognitionRes} from "./models/utils";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  loadingOrderInfo: boolean = true;
  bankGuarantee: boolean;
  fileLoading: boolean = false;

  order: OrderMain;
  orderItems: OrderItem[];
  file: IdNameEntity;
  accounts: Account[] = [];
  companyDocumentList: CompanyDocument[] = [];
  availableAnswers: string [] = ['yes', 'no', 'partially']
  mainForm: FormGroup;

  flatNodeMap: Map<DocumentFlatNode, CompanyDocument> = new Map<DocumentFlatNode, CompanyDocument>();
  nestedNodeMap: Map<CompanyDocument, DocumentFlatNode> = new Map<CompanyDocument, DocumentFlatNode>();
  treeControl: FlatTreeControl<DocumentFlatNode>;
  treeFlattener: MatTreeFlattener<CompanyDocument, DocumentFlatNode>;
  dataSource: MatTreeFlatDataSource<CompanyDocument, DocumentFlatNode>;

  recognizeResults:RecognitionRes;

  offerCompanyDocumentRequired:boolean;
  showCompanyDocumentOnPortal:boolean;
  canSetOfferCompanyDocument:boolean;

  @ViewChild('accountSearch')
  accountSearch: MatInput;
  @ViewChild('documentSearch')
  documentSearch: MatInput;

  constructor(private route: ActivatedRoute, private fb: FormBuilder,
              private portalService: PortalApiService, private dialogService: DialogService,
              private translateService: TranslateService, private telegramService: TelegramApiService) {
    telegramService.ready();
    translateService.use("ru");
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe({
          next: (value) => {
            const startapp = value['startapp']||"a1";
            this.initDataLoading(startapp)
          }
        }
      );
  }
  private translate(key:string) :string {
    return this.translateService.instant(key);
  }

  private initDataLoading(telegramCode:string) {
    this.portalService.getOrderData(telegramCode)
      .subscribe({
        next: (value) => {
          this.order = value.order;
          this.orderItems = this.order.orderItems;
          this.accounts = value.accounts;
          this.bankGuarantee = value.offerWithBankGuarantee;
          this.offerCompanyDocumentRequired = value.offerCompanyDocumentRequired;
          this.showCompanyDocumentOnPortal = value.showCompanyDocumentOnPortal;
          this.canSetOfferCompanyDocument = value.canSetOfferCompanyDocument;
        },
        complete: () => {
          this.mainForm = this.initForm(this.orderItems);
          this.initSubscriptions();
          this.loadingOrderInfo = false;
          this.initMainButton();
        }
      });
  }

  private initForm(orderItems: OrderItem[]): FormGroup {

    const createFormControl = (oi: OrderItem): FormGroup => {
      return this.fb.group({
        id: [oi.id],
        unit: [oi.unit],
        price: [oi.price],
        availableCount: [oi.availableCount, Validators.compose([Validators.required, nonZero])],
        amount: [oi.amount],
        deliveryDays: [oi.deliveryDays,Validators.compose([nonZero])],
        checked: [oi.checked],
        count: [{value: oi.count - oi.skipCount, disabled: true}],
        goodName: [{value: oi.goodName, disabled: true}],
      })
    }

    const array = this.fb.array(orderItems.map(createFormControl));

    const mainForm:FormGroup = this.fb.group({
      fileControl: ["", Validators.required],
      accounts: ["", Validators.required],
      deliveryIncluded: ["", Validators.required],
      prepaid: ["", Validators.required],
      prepaidPercent: [""],
      needDelay: ["", Validators.required],
      delay: [""],
      allAvailable: ["", Validators.required],
      validityPeriod: [""],
      doc: [{value: "", disabled: false}],
      checkAll: [this.orderItems.every(oi => oi.checked)],
      orderItems: array,
      comment: [""],
    });

    if(this.bankGuarantee) {
      mainForm.addControl('bankGuarantee', this.fb.control('', Validators.required),{});
    }
    if(this.offerCompanyDocumentRequired) {
      mainForm.addControl('docNumber', this.fb.control(''),{});
    }
    if(this.canSetOfferCompanyDocument) {
      mainForm.addControl('companyDocument', this.fb.control('',{}));
    }
    return mainForm;
  }

  get itemsFormArray(): FormArray {
    return this.mainForm.get('orderItems') as FormArray;
  }

  get itemControlArray(): AbstractControl[] {
    return this.itemsFormArray.controls;
  }

  get checkedItemControlArray(): AbstractControl[] {
    return this.itemControlArray.filter(ctrl => ctrl.get("checked").value)
  }

  private initTreeHelpers(): void {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<DocumentFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  private initSubscriptions(): void {
    this.initDelaySubscription();
    this.initPrepaidSubscription();
    this.initAllCheckSubscription();
    this.initItemsSubscription();
    this.disableCellsIfNeeded();
    this.disablePercentAndDelay();
    this.setAdditionalPropsIfNeeded();
    this.initAccountSubscription();
    this.initTreeHelpers();
  }

  private initDelaySubscription(): void {
    const delay = this.mainForm.get("delay");
    this.mainForm.get("needDelay").valueChanges
      .subscribe(value => {
        if (value) {
          if (this.mainForm.get("prepaidPercent").value == 100) {
            this.mainForm.get("prepaid").patchValue(false);
          }
          delay.enable();
          delay.setValidators([Validators.required, Validators.min(1)]);
        } else {
          delay.reset();
          delay.disable();
          delay.clearValidators();
        }
        delay.updateValueAndValidity();
      });
  }

  private initPrepaidSubscription(): void {
    const prepaidPercent = this.mainForm.get("prepaidPercent");
    this.mainForm.get("prepaid").valueChanges
      .subscribe(value => {
        if (value) {
          prepaidPercent.enable();
          prepaidPercent.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
        } else {
          prepaidPercent.reset();
          prepaidPercent.disable();
          prepaidPercent.clearValidators();
        }
        prepaidPercent.updateValueAndValidity();
      });
  }

  private initAllCheckSubscription(): void {
    const allAvailable = this.mainForm.get("allAvailable");
    allAvailable.valueChanges.subscribe(value => {
        if (value == 'yes') {
          this.itemControlArray.forEach(ctrl => ctrl.get("availableCount").patchValue(ctrl.get("count").value))
        }
      });
  }

  private initAccountSubscription(): void {
    const accounts = this.mainForm.get("accounts");
    accounts.valueChanges.subscribe(value => {
      if (value != null) {
        this.loadCompanyDocuments();
      }
    });
  }

  private initItemsSubscription(): void {
    this.itemControlArray.forEach(control => {
      const availableCountControl = control.get("availableCount");
      availableCountControl.valueChanges.subscribe(() => {
        this.checkValuesAndUpdateAvailability();
        this.checkValuesAndUpdateDelivery();
      });
      control.get("checked").valueChanges.subscribe(val => {
        if (!val) {
          availableCountControl.disable();
          control.get("deliveryDays").disable();
        } else {
          availableCountControl.enable();
          control.get("deliveryDays").enable();
        }
        if (this.itemControlArray.every(control => control.get("checked").value === true)) {
          this.mainForm.get("checkAll").patchValue(true);
        } else {
          this.mainForm.get("checkAll").patchValue(false);
        }
      });
    })
  }

  private initMainButton():void {
    this.telegramService.MainButton.setText(this.translate("telegramApp.sendOfferDlgTitle"));
    this.telegramService.MainButton.show();
    this.telegramService.MainButton.onClick(this.sendToProcess);
  }

  private disablePercentAndDelay():void {
    this.mainForm.get("delay").disable();
    this.mainForm.get("prepaidPercent").disable();
  }

  private setAdditionalPropsIfNeeded(): void {
    if (this.bankGuarantee) {
      this.mainForm.get("bankGuarantee").setValidators([Validators.required]);
      this.mainForm.get("bankGuarantee").updateValueAndValidity();
    }
  }

  private disableCellsIfNeeded(): void {
    this.checkedItemControlArray.forEach(ctrl => {
      ctrl.get("deliveryDays").disable();
      ctrl.get("availableCount").disable();
    });
  }

  private checkValuesAndUpdateAvailability(): void {
    const checkedItems = this.checkedItemControlArray;
    const allPresent = checkedItems.every(ctrl => ctrl.get("availableCount").value >= ctrl.get("count").value);
    const allNulls = checkedItems.every(ctrl => ctrl.get("availableCount").value == 0);

    if (allPresent) {
      this.mainForm.get("allAvailable").setValue("yes", {emitEvent: false});
    } else if (allNulls) {
      this.mainForm.get("allAvailable").setValue("no");
    } else {
      this.mainForm.get("allAvailable").setValue("partially");
    }

  }

  private checkValuesAndUpdateDelivery(): void {
    this.checkedItemControlArray
      .filter(ctrl => ctrl.get("availableCount").value == 0)
      .forEach(ctrl => ctrl.patchValue({deliveryDays: 1}));
  }

  startAccountSearch(): void {
    const filter = this.accountSearch.value.toLowerCase();
    this.accounts = this.accounts.filter(acc => acc?.company?.shortName?.toLowerCase().includes(filter));
  }

  loadCompanyDocuments(): void {
    this.portalService.getCompanyDocuments(this.mainForm.get("accounts").value.company.inn, this.order.customer.id)
      .subscribe((data: Partial<ServerDataForOffer>) => {
        if(data.documents) {
          this.companyDocumentList = data.documents;
          this.dataSource.data = this.rearrangeDocumentsAsTree(this.companyDocumentList);
        }
      });
  }

  startDocumentSearch(): void {
    const filter = this.documentSearch.value.toLowerCase();
    const data = this.companyDocumentList.filter(option => option.shortTitle.toLowerCase().includes(filter));
    this.dataSource.data = this.rearrangeDocumentsAsTree(data);
  }

  compareObjectsById(object1: { id: number }, object2: { id: number }): boolean {
    return object1?.id == object2?.id;
  }

  updateCounts(event: MatSelectChange): void {
    const controls = this.checkedItemControlArray;
    if (event.value === "yes") {
      controls.forEach(ctrl => ctrl.patchValue({availableCount: ctrl.get("count").value}))
    } else if (event.value === "no") {
      controls.forEach(ctrl => ctrl.patchValue({availableCount: 0}))
    }
  }

  sendToProcess(): void {
    const data: OuterOfferCreateModel = this.getData();

    if (data) {
      this.portalService.sendToProcess(data).subscribe({
        next: () => {
          this.dialogService.createDlg(this.translate("options.success"), this.translate("orders.orderReport.sendSuccess"));
          this.loadingOrderInfo = true;
          this.telegramService.closeApp();
        },
        error: error => {
          this.fileLoading = false;
          this.dialogService.handleError(this.translate("options.error"), this.dialogService.getError(error));
        }
      });
    }
  }

  private getData(): OuterOfferCreateModel {
    const items = this.processOrderItems();
    if (this.mainForm.invalid) {
      this.dialogService.createDlg(this.translate("options.error"), this.translate("orders.orderReport.mainFormInvalid"));
      return null;
    }
    if (items.length == 0 || !this.validItems()) {
      this.dialogService.createDlg(this.translate("options.error"), this.translate("orders.orderReport.formInvalid"));
      return null;
    }
    return OuterOfferCreateModel.createFromForms(this.mainForm, this.file?.id, items, this.order.id, this.recognizeResults);

  }

  private validItems(): boolean {
    return !this.checkedItemControlArray
      .some(ctrl => !ctrl.get("availableCount").value && !ctrl.get("deliveryDays").value);
  }

  private processOrderItems(): OrderItem[] {
    function buildOrderItem(ctrl: AbstractControl): OrderItem {
      const oi = new OrderItem();
      oi.id = ctrl.get("id").value;
      oi.goodName = ctrl.get("goodName").value;
      oi.count = ctrl.get("count").value;
      oi.unit = ctrl.get("unit").value;
      oi.availableCount = ctrl.get("availableCount").value;
      oi.deliveryDays = ctrl.get("deliveryDays").value;
      oi.price = ctrl.get("price").value;
      oi.isAvailable = ctrl.get("availableCount").value && !ctrl.get("deliveryDays").value;
      oi.amount = ctrl.get("amount").value;
      return oi;
    }

    return this.itemControlArray
      .filter(ctrl => ctrl.get("checked").value)
      .map(buildOrderItem);
  }

  addOfferFile(fileFromForm: File): void {
    this.fileLoading = true;
    const processFile = (file: IdNameEntity) => {
      this.portalService.sendRecognizeRequest(file.id)
        .subscribe((data: any) => {
          if (data.result) {
            this.recognizeResults = data?.data;
            this.portalService.sendCheckDuplicatesRequest(file.id).subscribe({
              next: () => {
                this.mainForm.get("fileControl").patchValue(`${file.name}`)
                this.fileLoading = false
              },
              error: error => {
                this.dialogService.handleError(this.translate("options.error"), this.dialogService.getError(error))
                this.fileLoading = false
              }
            });
          }
        });
    };

    if (fileFromForm) {
      const formData = new FormData();
      formData.append('file', fileFromForm);
      this.portalService.uploadFileReal(formData, this.order.id).subscribe({
        next: (data: { id: number }) => {
          if (data.id) {
            this.file = {id: data.id, name: fileFromForm.name};
            processFile(this.file);
          }
        },
        error: error => {
          this.dialogService.handleError(this.translate("options.error"), this.dialogService.getError(error.error.error || error.error));
        },
        complete:()=> {
        }
      });
    }
  }

  updateForm($event: MatSelectChange): void {
    if ($event.value) {
      return;
    }
    if ($event.source.id == "needDelay" && !this.mainForm.get('prepaid').value) {
      this.mainForm.get('prepaid').setValue(true);
    } else if ($event.source.id == "prepaidRequired" && !this.mainForm.get('needDelay').value) {
      this.mainForm.get('needDelay').setValue(true);
    }
  }

  rearrangeDocumentsAsTree(objects: CompanyDocument[]): CompanyDocument[] {

    if (!objects) return [];

    const objectsMap = {};
    objects.forEach(o => {
      if (o.parent?.id) {
        let exist = objects.some(ob => ob.id === o.parent.id);
        if (!exist) {
          delete o.parent;
        }
      }
      objectsMap[o.id] = o;
    });

    objects.forEach(object => {
      if (object?.parent?.id) {
        const parent = objectsMap[object.parent.id];
        if (parent) {
          parent.children ??= [];
          parent.children.every(child => child.id != object.id) && parent.children.push(object);
          object.parent = parent;
        }
      }
    });
    return objects.filter(o => !o.parent);
  }

  deleteFile(): void {
    this.mainForm.get("fileControl").reset();
    this.file = null;
  }

  selectAll($event: any): void {
    this.mainForm.get("checkAll").patchValue($event.checked);
    this.itemControlArray.forEach(ctrl => ctrl.get("checked").patchValue($event.checked));
  }

  getLevel = (node: DocumentFlatNode) => node.level;

  isExpandable = (node: DocumentFlatNode) => node.expandable;

  getChildren = (node: CompanyDocument): CompanyDocument[] => node.children;

  hasChild = (_: number, _nodeData: DocumentFlatNode) => _nodeData.expandable;

  transformer = (node: CompanyDocument, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node
        ? existingNode
        : new DocumentFlatNode();
    flatNode.item = node;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  checkPrepaidPercentValue() {
    if (this.mainForm.get("prepaidPercent").value >= 100) {
      this.mainForm.get("prepaidPercent").patchValue(100);
      this.mainForm.get("needDelay").patchValue(false);
    }
  }

}

class DocumentFlatNode {
  item: CompanyDocument;
  level: number;
  expandable: boolean;
}

@Pipe({
  name: 'accountNameTransform',
  standalone: true,
  pure: false
})
export class AccountNameTransform implements PipeTransform {
  transform(account: Account): string {
    return `${account.company?.shortName} ${account.name} ${account.currency?.name} ${account.accountType?.name}`
  }
}
